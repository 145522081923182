import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

var fixedamount = '100';

function changeValue(wert) {
  fixedamount = wert;
  // console.log(fixedamount);
  // // GiftPage.forceUpdate();
}

const GiftPage = () => (
  <Layout bg="bg-primary-bg">
    <Seo title="Gutscheine" />
    <div className="pt-32 boxed generic-page bg-voucher">
      <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
        <h1 className="lg:!text-5xl ">Gutscheine</h1>
        <h2>Verschenken Sie ein Erlebnis der Superlative</h2>
        <p className="max-w-2xl">
          Besondere Menschen verdienen besondere Geschenke. Ein hochwertiger Gutschein von PEAK MOTORS sorgt nicht nur für ein Lächeln auf den Lippen, sondern schafft auch
          Erinnerungen die für immer bleiben.
        </p>
        <p id="top" className="max-w-2xl">
          Ob der Anlass ein Jubiläum ist, ein Geburtstag oder einfach das lang ersehnte Wochenende, ob Sie einen Geschäftspartner beschenken wollen oder Ihren Liebsten. Uns bleibt
          nur noch eins zu sagen: Hier der Schlüssel, bon voyage!
        </p>
      </div>
    </div>

    <div className="w-full bg-peak">
      <div className="pb-40 boxed generic-page">
        <div className="grid-cols-2 gap-12 md:grid lg:gap-24">
          <div id="widget" data-sal="slide-right" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500">
            <h2 className="pb-6">Einfach den gewünschten Betrag wählen</h2>
            <div className="grid grid-flow-row grid-cols-3 col-span-1 gap-4 mb-12 text-center md:mb-0">
              <Link to="#top" className="hidden fixedamount-button md:block" onClick={() => changeValue(100)}>
                100,-
              </Link>
              <Link to="#top" className="hidden fixedamount-button md:block" onClick={() => changeValue(200)}>
                200,-
              </Link>
              <Link to="#top" className="hidden fixedamount-button md:block" onClick={() => changeValue(300)}>
                300,-
              </Link>
              <Link to="#top" className="hidden fixedamount-button md:block" onClick={() => changeValue(400)}>
                400,-
              </Link>
              <Link to="#top" className="fixedamount-button hidden md:block text-secondary-font focus:!text-white relative" onClick={() => changeValue(500)}>
                500,-<span className="gift-sub">Mit kostenloser Geschenkbox</span>
              </Link>
              <Link to="#top" className="fixedamount-button hidden md:block text-secondary-font focus:!text-white relative" onClick={() => changeValue(1000)}>
                1000,-<span className="gift-sub">Mit kostenloser Geschenkbox</span>
              </Link>

              <Link to="#widget" className="block fixedamount-button md:hidden" onClick={() => changeValue(100)}>
                100,-
              </Link>
              <Link to="#widget" className="block fixedamount-button md:hidden" onClick={() => changeValue(200)}>
                200,-
              </Link>
              <Link to="#widget" className="block fixedamount-button md:hidden" onClick={() => changeValue(300)}>
                300,-
              </Link>
              <Link to="#widget" className="block fixedamount-button md:hidden" onClick={() => changeValue(400)}>
                400,-
              </Link>
              <Link to="#widget" className="fixedamount-button block md:hidden text-secondary-font focus:!text-white relative" onClick={() => changeValue(500)}>
                500,-<span className="gift-sub">Mit kostenloser Geschenkbox</span>
              </Link>
              <Link to="#widget" className="fixedamount-button block md:hidden text-secondary-font focus:!text-white relative" onClick={() => changeValue(1000)}>
                1000,-<span className="gift-sub">Mit kostenloser Geschenkbox</span>
              </Link>
            </div>
          </div>
          <div className="w-fit justify-self-center">
            <rentware-giftcard fixed-amount={fixedamount}></rentware-giftcard>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-peak">
      <div
        className="grid max-w-screen-lg grid-cols-1 gap-6 pb-16 boxed md:grid-cols-3 lg:pb-36"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        <div className="img-post">
          <StaticImage src="../assets/images/gutschein-merch-01.jpeg" alt="A dinosaur" placeholder="blurred" />
        </div>

        <div className="img-post">
          <StaticImage src="../assets/images/gutschein-merch-02.jpeg" alt="A dinosaur" placeholder="blurred" />
        </div>

        <div className="img-post">
          <StaticImage src="../assets/images/gutschein-merch-03.jpeg" alt="A dinosaur" placeholder="blurred" />
        </div>
      </div>
    </div>
  </Layout>
);

export default GiftPage;
